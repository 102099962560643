import { OPERATOR_TYPE } from '@features/filters/types';
import i18n from '@features/i18n';

export const scheduledCampaignTextLabel = {
  unsavedChanges: i18n.t('common.modal.unsaved.title'),
  excludedPlayers: i18n.t('campaign.common.players.excluded'),
  uploadFileHint: i18n.t('campaign.common.target.label.file'),
  uploadControl: i18n.t('campaign.common.target.control.file.size'),
  uploadControlTitle: i18n.t('campaign.common.target.control.file.upload'),
  uploadFileTooltip: i18n.t('campaign.common.target.control.file.tooltip'),
  activatingStatus: i18n.t(
    'campaign.scheduled.details.modal.status.active.title'
  ),
  creationCampaignTooltip: i18n.t(
    'campaign.scheduled.modal.create.label.tooltip'
  ),
  changeBrandPopup: i18n.t(
    'campaign.common.general.modal.brand.change.scheduled'
  ),
  calculationTypeTooltip: i18n.t(
    'campaign.scheduled.details.tier.label.type.tooltip'
  ),
  deactivatingStatus: i18n.t(
    'campaign.scheduled.details.modal.status.inactive.title'
  ),
  activitiesPeriodTooltip: i18n.t(
    'campaign.scheduled.details.tier.label.period.tooltip'
  ),
  emptySportOrCasinoConditionsError: i18n.t(
    'common.validation.casino.or.sport.required'
  ),
  applicablePlayers: i18n.t(
    'campaign.scheduled.grid.modal.info.segment.applicable.label'
  ),
  additionalInfoNoSegment: i18n.t(
    'campaign.scheduled.grid.modal.info.label.segment.hint'
  ),
  tabDisableConfirmation: i18n.t(
    'campaign.scheduled.details.control.casino.enable.tooltip'
  ),
  emptyExecutionSchedule: i18n.t(
    'campaign.scheduled.details.execution.label.occurrences.empty'
  ),
  repeatOptionsTooltip: i18n.t(
    'campaign.scheduled.details.execution.control.frequency.tooltip'
  ),
  nextOccurrenceListWarning: i18n.t(
    'campaign.scheduled.details.execution.label.occurrences.hint'
  ),
  uploadTypeTooltipOptions: [
    i18n.t('campaign.common.target.control.append.tooltip'),
    i18n.t('campaign.common.target.control.replace.tooltip'),
  ],
};

export const initialFilters = {
  id: { value: '', inversion: false, operator: OPERATOR_TYPE.EQUAL },
  brands: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  enabled: { value: false, inversion: true, operator: OPERATOR_TYPE.EQUAL },
  campaignName: { value: '', inversion: false, operator: OPERATOR_TYPE.LIKE },
  primaryCriterion: { value: [], inversion: false, operator: OPERATOR_TYPE.IN },
  targetGroup: { value: null, inversion: false, operator: OPERATOR_TYPE.EQUAL },
  bonuses: {
    value: [],
    inversion: false,
    operator: OPERATOR_TYPE.IN,
  },
};

export enum EXECUTION_CONDITIONS_FIELD {
  REPEAT = 'repeatOptions',
  START_DATE = 'startDateTime',
  STOP_REPEAT = 'stopRepeat',
}
